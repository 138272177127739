@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
.signin-conatiner {
  width: 100%;
  height: 100%;
}

.body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  margin: 0 !important;
}

.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  background-image: url("https://www.bamitech.net/images/Bamitech-1467619937.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.form-block-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  -moz-transition: all 0.85s ease-in-out;
  -webkit-transition: all 0.85s ease-in-out;
  transition: all 0.85s ease-in-out;
}
.form-block-wrapper--is-login {
  opacity: 0.92;
  background-color: #2c497f;
}
.form-block-wrapper--is-signup {
  opacity: 0.94;
  background-color: #433b7c;
}

.form-block {
  position: relative;
  margin: 100px auto 0;
  width: 325px;
  padding: 25px;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 2px;
  color: #fff;
  -webkit-box-shadow: 0px 0px 16px 9px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 16px 9px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 16px 9px rgba(0, 0, 0, 0.07);
}

.form-block__header {
  margin-bottom: 20px;
}
.form-block__header h1 {
  font-size: 30px;
  margin: 0 0 20px;
}

.form-block__toggle-block {
  position: relative;
}
.form-block__toggle-block span {
  font-size: 13px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.65);
}

.form-block__input-wrapper {
  height: 126px;
  position: relative;
  margin-bottom: 2px;
  -moz-transition: all 0.25s cubic-bezier(0.36, 1, 0.62, 0.98) 0.3s;
  -webkit-transition: all 0.25s cubic-bezier(0.36, 1, 0.62, 0.98) 0.3s;
  transition: all 0.25s cubic-bezier(0.36, 1, 0.62, 0.98) 0.3s;
}
.form-block--is-signup .form-block__input-wrapper {
  height: 252px;
  -moz-transition: all 0.5s cubic-bezier(0.36, 1, 0.62, 0.98) 0.2s;
  -webkit-transition: all 0.5s cubic-bezier(0.36, 1, 0.62, 0.98) 0.2s;
  transition: all 0.5s cubic-bezier(0.36, 1, 0.62, 0.98) 0.2s;
}

.form-group--signup {
  position: absolute;
  top: 0;
  left: 150%;
  width: 100%;
}

.form-group__input {
  display: block;
  font-size: 15px;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 14px 10px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1px;
}
.form-group__input:focus {
  outline: none;
  border: 1px solid #fff;
  background: transparent;
}
.form-block--is-signup .form-group__input {
  transform: translateX(-150%);
}
.form-group--login .form-group__input {
  opacity: 1;
}
.form-block--is-signup .form-group--login .form-group__input {
  opacity: 0;
}
.form-group--signup .form-group__input {
  opacity: 0;
}
.form-block--is-signup .form-group--signup .form-group__input {
  opacity: 1;
}

.form-group__input:nth-of-type(1) {
  -moz-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.085s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  -webkit-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.085s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.085s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
}

.form-group__input:nth-of-type(2) {
  -moz-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.17s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  -webkit-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.17s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.17s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
}

.form-group__input:nth-of-type(3) {
  -moz-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.255s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  -webkit-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.255s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.255s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
}

.form-group__input:nth-of-type(4) {
  -moz-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.34s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  -webkit-transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.34s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
  transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98) 0.34s,
    opacity 0.3s ease-in-out 0.2s, background 0.15s ease-in-out,
    border 0.3s ease-in-out;
}

.button-form {
  display: inline-block;
  padding: 15px 12px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: none;
  border-radius: 2px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0px 0px 13px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 13px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 13px 8px rgba(0, 0, 0, 0.1);
}
.button-form--primary {
  background: #ed6a5e;
  color: #fff;
}
.button-form:hover  {
  -webkit-box-shadow: 0px 0px 18px 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 18px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 18px 15px rgba(0, 0, 0, 0.15);
}

.full-width {
  width: 100%;
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label:before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  width: 43px;
  height: 8px;
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
input[type="checkbox"] + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 18px;
  width: 25px;
  height: 25px;
  background: #ed6a5e;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.15);
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
input[type="checkbox"]:checked + label:after {
  right: 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}

:-moz-placeholder {
  /* Firefox 18- */
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}

.tooltip {
  padding: 7px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: 0;
}

.button-google {
  display: block;
  width: 100%;
}

.logo{
  display: flex;
  height: 3em;
  justify-content: center;
  margin-bottom: 1em;
}
